// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as Provider from "../../../models/Provider.res.js";
import * as PreloadedSearchLocation from "../../../models/PreloadedSearchLocation.res.js";

function exec(providerId, pageNum, sortBy, query) {
  var query$1 = PreloadedSearchLocation.Index.query(query);
  var page = PreloadedSearchLocation.Index.page(pageNum);
  var sortBy$1 = PreloadedSearchLocation.Index.sortBy(sortBy);
  return Rest.$$fetch("/providers/" + ID.toString(providerId) + "/locations?" + query$1 + page + sortBy$1, "Get", {
              NAME: "Json",
              VAL: Provider.$$Location.Index.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* ID Not a pure module */
