// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CreateProviderRequest from "./requests/CreateProviderRequest.res.js";
import * as FetchAllProvidersRequest from "./requests/FetchAllProvidersRequest.res.js";
import * as CreateProviderImageRequest from "./requests/CreateProviderImageRequest.res.js";
import * as CreateProviderVideoRequest from "./requests/CreateProviderVideoRequest.res.js";
import * as DeleteProviderImageRequest from "./requests/DeleteProviderImageRequest.res.js";
import * as DeleteProviderVideoRequest from "./requests/DeleteProviderVideoRequest.res.js";
import * as UpdateProviderDraftRequest from "./requests/UpdateProviderDraftRequest.res.js";
import * as UpdateProviderImageRequest from "./requests/UpdateProviderImageRequest.res.js";
import * as UpdateProviderVideoRequest from "./requests/UpdateProviderVideoRequest.res.js";
import * as ApproveProviderDraftRequest from "./requests/ApproveProviderDraftRequest.res.js";
import * as FetchProvidersFilterRequest from "./requests/FetchProvidersFilterRequest.res.js";
import * as UpdateProviderActiveRequest from "./requests/UpdateProviderActiveRequest.res.js";
import * as ReorderProviderImagesRequest from "./requests/ReorderProviderImagesRequest.res.js";
import * as ReorderProviderVideosRequest from "./requests/ReorderProviderVideosRequest.res.js";
import * as FetchProviderLocationsRequest from "./requests/FetchProviderLocationsRequest.res.js";
import * as DestroyProviderLocationRequest from "./requests/DestroyProviderLocationRequest.res.js";
import * as FetchProvidersDashboardRequest from "./requests/FetchProvidersDashboardRequest.res.js";
import * as UpdateProviderDraftLogoRequest from "./requests/UpdateProviderDraftLogoRequest.res.js";
import * as ActivateProvidersInBatchRequest from "./requests/ActivateProvidersInBatchRequest.res.js";
import * as FetchProviderLocationsMapRequest from "./requests/FetchProviderLocationsMapRequest.res.js";
import * as GetLocationNameValidationRequest from "./requests/GetLocationNameValidationRequest.res.js";
import * as UpdateProviderImageActiveRequest from "./requests/UpdateProviderImageActiveRequest.res.js";
import * as UpdateProviderVideoActiveRequest from "./requests/UpdateProviderVideoActiveRequest.res.js";
import * as FetchProviderShowLocationsRequest from "./requests/FetchProviderShowLocationsRequest.res.js";
import * as FetchProviderLocationsIndexRequest from "./requests/FetchProviderLocationsIndexRequest.res.js";
import * as FetchLocationBrochuresFilterRequest from "./requests/FetchLocationBrochuresFilterRequest.res.js";
import * as FetchProviderBrochuresFilterRequest from "./requests/FetchProviderBrochuresFilterRequest.res.js";
import * as UpdateProviderActiveDashboardRequest from "./requests/UpdateProviderActiveDashboardRequest.res.js";
import * as CreateProviderDashboardEditProviderTsd from "./requests/CreateProviderDashboardEditProviderTsd.res.js";
import * as FetchProviderLocationsDashboardRequest from "./requests/FetchProviderLocationsDashboardRequest.res.js";
import * as UpdateProviderDashboardEditProviderTsd from "./requests/UpdateProviderDashboardEditProviderTsd.res.js";
import * as DestroyProviderDashboardEditProviderTsd from "./requests/DestroyProviderDashboardEditProviderTsd.res.js";
import * as FetchProviderDashboardEditProviderTsdsIndex from "./requests/FetchProviderDashboardEditProviderTsdsIndex.res.js";
import * as FetchProviderDashboardEditProviderTsdTsdsFilter from "./requests/FetchProviderDashboardEditProviderTsdTsdsFilter.res.js";

var Show = {
  locations: FetchProviderShowLocationsRequest.exec
};

var ProviderTsd = {
  index: FetchProviderDashboardEditProviderTsdsIndex.exec,
  create: CreateProviderDashboardEditProviderTsd.exec,
  update: UpdateProviderDashboardEditProviderTsd.exec,
  destroy: DestroyProviderDashboardEditProviderTsd.exec,
  tsdsFilter: FetchProviderDashboardEditProviderTsdTsdsFilter.exec
};

var Edit = {
  ProviderTsd: ProviderTsd
};

var Dashboard = {
  index: FetchProvidersDashboardRequest.exec,
  Edit: Edit
};

var $$Location = {
  index: FetchProviderLocationsIndexRequest.exec,
  map: FetchProviderLocationsMapRequest.exec
};

var create = CreateProviderRequest.exec;

var locations = FetchProviderLocationsRequest.exec;

var updateActive = UpdateProviderActiveRequest.exec;

var updateActiveDashboard = UpdateProviderActiveDashboardRequest.exec;

var updateDraft = UpdateProviderDraftRequest.exec;

var approve = ApproveProviderDraftRequest.exec;

var updateDraftLogo = UpdateProviderDraftLogoRequest.exec;

var createImage = CreateProviderImageRequest.exec;

var createVideo = CreateProviderVideoRequest.exec;

var reorderImages = ReorderProviderImagesRequest.exec;

var reorderVideos = ReorderProviderVideosRequest.exec;

var deleteImage = DeleteProviderImageRequest.exec;

var deleteVideo = DeleteProviderVideoRequest.exec;

var updateImage = UpdateProviderImageRequest.exec;

var updateVideo = UpdateProviderVideoRequest.exec;

var updateImageActive = UpdateProviderImageActiveRequest.exec;

var updateVideoActive = UpdateProviderVideoActiveRequest.exec;

var validateLocationName = GetLocationNameValidationRequest.exec;

var activateProvidersInBatch = ActivateProvidersInBatchRequest.exec;

var fetchProvidersFilter = FetchProvidersFilterRequest.exec;

var fetchLocationBrochuresFilter = FetchLocationBrochuresFilterRequest.exec;

var fetchProviderBrochuresFilter = FetchProviderBrochuresFilterRequest.exec;

var fetchAllProviders = FetchAllProvidersRequest.exec;

var locationsDashboard = FetchProviderLocationsDashboardRequest.exec;

var destroyLocation = DestroyProviderLocationRequest.exec;

export {
  create ,
  locations ,
  updateActive ,
  updateActiveDashboard ,
  updateDraft ,
  approve ,
  updateDraftLogo ,
  createImage ,
  createVideo ,
  reorderImages ,
  reorderVideos ,
  deleteImage ,
  deleteVideo ,
  updateImage ,
  updateVideo ,
  updateImageActive ,
  updateVideoActive ,
  validateLocationName ,
  activateProvidersInBatch ,
  fetchProvidersFilter ,
  fetchLocationBrochuresFilter ,
  fetchProviderBrochuresFilter ,
  fetchAllProviders ,
  locationsDashboard ,
  destroyLocation ,
  Show ,
  Dashboard ,
  $$Location ,
}
/* CreateProviderRequest Not a pure module */
